.model-background {
  background-color: #1a1e34;
  width: 50vw;
  align-self: center;
}

.modal-text {
  color: #1d8cf8;
}

.card_bg {
  background-color: #222223;
}

.download_style{
   color: "#fff";
}

.btn-light{
  background-image: linear-gradient(to bottom left, #1d8cf8, #3358f4, #1d8cf8);
}